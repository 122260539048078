import {
  Button,
  Container,
  Grid,
  Group,
  Select,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect } from 'react';

import { CustomLoader } from '../../components/custom-loader';
import { rootGoBack } from '../../components/custom-router';
import { Page } from '../../components/page';
import { successNotification } from '../../providers/mantine-notifications';
import { useCreateUserAccess } from '../../data/hooks/user';
import { UserRole, UserType } from '../../models/user';

export function UserAccessCreate() {
  const { fetch: createUserFetcher, loading: createUserLoader } =
    useCreateUserAccess();

  const form = useForm({
    initialValues: {
      username: '',
      role: '',
      type: 'user',
      accessCode: '',
      password: '',
      manualLogin: false,
    },
  });

  async function handleSubmit({ ...values }: typeof form.values) {
    let userType = UserType.MANAGER;
    if (values.role === UserRole.NONE) {
      userType = UserType.MASTER;
    }

    await createUserFetcher({
      data: {
        ...values,
        role: values.role as UserRole,
        type: userType,
      },
      onSuccess: () => {
        rootGoBack();
        successNotification({
          title: 'Acesso criado.',
          message: 'tudo certo.',
        });
      },
    });
  }

  useEffect(() => {}, []);

  return (
    <Page>
      <CustomLoader loading={createUserLoader} />
      <Container bg="white" h="100%">
        <Button
          leftIcon={<IconArrowLeft />}
          onClick={rootGoBack}
          mt={16}
          mb={16}
          variant="subtle"
        >
          voltar
        </Button>
        <Title>Cadastro de acesso</Title>
        <Text color="gray" mb={16}>
          dados do novo acesso
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid columns={2} maw={500}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome completo"
                type="text"
                {...form.getInputProps('username')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                withAsterisk
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={[
                  {
                    value: UserRole.MANAGER_VIEWER,
                    label: 'Gestor Visualizar',
                  },
                  {
                    value: UserRole.MANAGER_CREATOR,
                    label: 'Gestor Editor',
                  },
                  {
                    value: UserRole.NONE,
                    label: 'Master',
                  },
                ]}
                {...form.getInputProps('role')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Código de Acesso"
                placeholder="acesso"
                type="text"
                {...form.getInputProps('accessCode')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Senha"
                placeholder="senha"
                type="text"
                {...form.getInputProps('password')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Switch
                mt={8}
                mb={8}
                checked={form.values.manualLogin}
                color="teal"
                size="sm"
                label="Permitir acesso sem biometria?"
                thumbIcon={
                  form.values.manualLogin ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...form.getInputProps('manualLogin')}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
