import {
  Button,
  Container,
  Grid,
  Group,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useState } from 'react';

import { CustomLoader } from '../../components/custom-loader';
import { rootGoBack, rootNavigate } from '../../components/custom-router';
import { Page } from '../../components/page';
import { createCompanyRequest } from '../../data/company';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums/routes';
import { clearMask, formatCEP, formatCNPJ } from '../../utils/masks';

export function CompanyCreate() {
  const [pageLoading, setPageLoading] = useState(false);
  const form = useForm({
    initialValues: {
      socialReason: '',
      fantasyName: '',
      cnpj: '',
      addressZipCode: '',
      addressCity: '',
      addressNumber: '',
      addressComplement: '',
      addressStreet: '',
    },
  });

  async function handleSubmit({ ...values }: typeof form.values) {
    try {
      setPageLoading(true);
      await createCompanyRequest({
        ...values,
        cnpj: clearMask(values.cnpj) ?? '',
      });
      setPageLoading(false);
      successNotification({
        title: 'Empresa cadastrada.',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.COMPANIES);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao cadastrar empresa.',
        message: 'tente novamente.',
      });
    }
  }

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Container bg="white" h="100%">
        <Button
          leftIcon={<IconArrowLeft />}
          onClick={rootGoBack}
          mt={16}
          mb={16}
          variant="subtle"
        >
          voltar
        </Button>
        <Title>Cadastro de empresa</Title>
        <Text color="gray" mb={16}>
          dados da nova empresa
        </Text>
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid gutter="xs" columns={2} m={8} w={600} maw={700}>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="Razão social"
                placeholder="razão social da empresa"
                type="text"
                {...form.getInputProps('socialReason')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="Nome Fantasia"
                placeholder="nome fantasia da empresa"
                type="text"
                {...form.getInputProps('fantasyName')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="CNPJ"
                placeholder="documento da empresa"
                type="text"
                onChange={(e) =>
                  form.setFieldValue('cnpj', formatCNPJ(e.target.value))
                }
                value={form.values.cnpj}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="CEP"
                placeholder="digite o CEP do endereço"
                type="text"
                onChange={(e) => {
                  form.setFieldValue(
                    'addressZipCode',
                    formatCEP(e.target.value),
                  );
                }}
                value={form.values.addressZipCode}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Endereço"
                placeholder="digite o endereço"
                type="text"
                name="addressStreet"
                {...form.getInputProps('addressStreet')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Número"
                placeholder="digite o número"
                type="text"
                name="addressNumber"
                {...form.getInputProps('addressNumber')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Complemento"
                placeholder="digite o complemento do endereço"
                type="text"
                name="addressComplement"
                {...form.getInputProps('addressComplement')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Cidade"
                placeholder="digite a cidade"
                type="text"
                name="addressCity"
                {...form.getInputProps('addressCity')}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
