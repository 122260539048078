import { WorkType } from '../../models/daily-work';

export const workJustification = [
  {
    value: 'medical_certificate',
    label: 'Atestado',
  },
  {
    value: 'bank_hour',
    label: 'Banco de horas',
  },
  {
    value: 'death_certificate',
    label: 'Licença nojo (atestado de óbito)',
  },
  {
    value: 'marriage_certificate',
    label: 'Licença gala (casamento)',
  },
  {
    value: 'maternity_certificate',
    label: 'Licença maternidade',
  },
  {
    value: 'paternity_certificate',
    label: 'Licença paternidade',
  },
  {
    value: 'breastfeeding_certificate',
    label: 'Licença amamentação',
  },
  {
    value: 'INSS',
    label: 'INSS',
  },
  {
    value: 'declaration_of_attendance',
    label: 'Declaração de Comparecimento',
  },
  {
    value: 'accompanying_statement',
    label: 'Declaração de Acompanhante',
  },
  {
    value: 'vacation',
    label: 'Férias',
  },
  {
    value: 'dismissal',
    label: 'Desligamento',
  },
  {
    value: 'compensatory_clearance',
    label: 'Folga compensatória',
  },
  {
    value: 'occupational_medical_exam',
    label: 'Exame médico ocupacional',
  },
  {
    value: 'suspension',
    label: 'Suspensão',
  },
];

export const faultJustification = [
  {
    value: 'partial_fault',
    label: 'Falta Parcial',
  },
  {
    value: 'medical_certificate',
    label: 'Atestado',
  },
];

export const dailyWorkTypeHumanized = {
  [WorkType.JUSTIFICATION]: 'justificativa',
  [WorkType.WORK]: 'horário',
  [WorkType.FAULT]: 'falta',
};

export const dailyWorkJustificationHumanized = {
  medical_certificate: 'Atestado',
  bank_hour: 'Banco de horas',
  death_certificate: 'Atestado de óbito',
  marriage_certificate: 'Casamento',
  maternity_certificate: 'Licença maternidade',
  paternity_certificate: 'Licença paternidade',
  breastfeeding_certificate: 'Licença amamentação',
  INSS: 'INSS',
  declaration_of_attendance: 'Declaração de Comparecimento',
  accompanying_statement: 'Declaração de Acompanhante',
  vacation: 'Férias',
  partial_fault: 'Falta Parcial',
  dismissal: 'Desligamento',
  compensatory_clearance: 'Folga compensatória',
  occupational_medical_exam: 'Exame médico ocupacional',
  suspension: 'Suspensão',
};
