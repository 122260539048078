export const errorsTranslate: Record<string, string> = {
  'Insufficient funds.': 'Valor em caixa insuficiente para essa operação.',
  'Insuficient money.': 'Valor em caixa insuficiente para essa operação.',
  "Can't generate with paid days.": 'Já existem pagamentos para estes dias.',
  "Transport it's already been paid to this user.":
    'VT já foi pago para o usuário.',
  'Already has an register for this date.':
    'Já existe um registro para este dia.',
  'Records not found!': 'Sem registros encontrados!',
  "Can't find this company.": 'Empresa não encontrada.',
  'Date must be from 2 days': 'A data deve ser a partir de 48 horas',
  "Can't add this value.": 'Valor inválido',
  'Action already registered.': 'Ponto já registrado',
  "You can't do this.": 'Você não pode fazer isso',
  "Can't do this.": 'Sem autorização',
  'Failed to write file': 'Falha ao escrever arquivo.',
  'Creation date must be today': 'Data de criação deve ser hoje',
};
