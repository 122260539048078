import { useFetch } from '../../../hooks/useFetch';
import { User } from '../../../models/user';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseCreateUserAccessProps,
  UseGetUsersPaginatedProps,
  UseRestoreUserProps,
  UseShowUserProps,
  UseUpdateUserAccessProps,
} from '../../../utils/types/data/hooks/users';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import {
  createUserAccessRequest,
  getUsersRequest,
  restoreUserRequest,
  showUserRequest,
  updateUserAccessRequest,
} from '../../services/user';
import { geteUsersPaginatedRequest } from '../../user';

export function useGetUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<User[]>();

  const fetch = () =>
    fetchData({
      fetcher: () => getUsersRequest(),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRestoreUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ onSuccess, param }: UseRestoreUserProps) =>
    fetchData({
      fetcher: () => restoreUserRequest(param),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao reativar usuário.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetUsersPaginated() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<User>>();

  const fetch = ({ query }: UseGetUsersPaginatedProps) =>
    fetchData({
      fetcher: () => geteUsersPaginatedRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCreateUserAccess() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseCreateUserAccessProps) =>
    fetchData({
      fetcher: () => createUserAccessRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao criar acesso.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdateUserAccess() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, id, onSuccess }: UseUpdateUserAccessProps) =>
    fetchData({
      fetcher: () => updateUserAccessRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao editar acesso.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useShowUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<User>();

  const fetch = ({ id, onSuccess }: UseShowUserProps) =>
    fetchData({
      fetcher: () => showUserRequest(id),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar dados do usuário.',
          message: translateServerHttpErrors(error, 'Tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
