import { Route, Routes } from 'react-router-dom';
import useAuthContext from '../hooks/useAuthContext';
import { UserRole, UserType } from '../models/user';
import { CashFlowList } from '../pages/cash-flow-list';
import { EmployeeTransportList } from '../pages/employee-transport-list';
import { Home } from '../pages/home';

import { Login } from '../pages/login';
import { PageNotFound } from '../pages/not-found';
import { UserCreate } from '../pages/user-create';
import { UserEdit } from '../pages/user-edit';
import { UserList } from '../pages/user-list';
import { DailyWorkCreate } from '../pages/daily-work-create';
import { DailyWorkList } from '../pages/daily-work-list';
import { AppRoutePaths } from '../utils/enums/routes';
import { CompanyCreate } from '../pages/company-create';
import { CompanyList } from '../pages/company-list';
import { CompanyEdit } from '../pages/company-edit';
import { BankHoursList } from '../pages/bank-hours-list';
import { LoginBiometric } from '../pages/login-biometric';
import { WorkScaleList } from '../pages/work-scale-list';
import { WorkScaleCreate } from '../pages/work-scale-create';
import { WorkScaleEdit } from '../pages/work-scale-edit';
import { WorkAlerts } from '../pages/work-alerts';
import { UserAccessCreate } from '../pages/user-access-create';
import { UserAccessEdit } from '../pages/user-access-edit';

export function Router() {
  const { user } = useAuthContext();

  function genericManagerRoutes() {
    return (
      <>
        <Route path={AppRoutePaths.HOME} element={<Home />} />
        <Route path={AppRoutePaths.USERS} element={<UserList />} />
        <Route path={AppRoutePaths.COMPANIES} element={<CompanyList />} />
        <Route path={AppRoutePaths.WORK_HISTORY} element={<DailyWorkList />} />
        <Route
          path={AppRoutePaths.EMPLOYEE_TRANSPORT}
          element={<EmployeeTransportList />}
        />
        <Route path={AppRoutePaths.BANK_HOURS} element={<BankHoursList />} />
      </>
    );
  }

  function managerCreatorRoutes(role: UserRole) {
    return (
      role === UserRole.MANAGER_CREATOR && (
        <>
          <Route path={AppRoutePaths.USERS_CREATE} element={<UserCreate />} />
          <Route
            path={AppRoutePaths.COMPANIES_CREATE}
            element={<CompanyCreate />}
          />
          <Route
            path={AppRoutePaths.COMPANIES_EDIT}
            element={<CompanyEdit />}
          />
          <Route path={AppRoutePaths.USERS_EDIT} element={<UserEdit />} />
          <Route path={AppRoutePaths.CASH_FLOW} element={<CashFlowList />} />
        </>
      )
    );
  }

  return (
    <Routes>
      <Route path={AppRoutePaths.BIOMETRIC_LOGIN} element={<Login />} />
      <Route path={AppRoutePaths.LOGIN} element={<LoginBiometric />} />
      <Route path={AppRoutePaths.NOT_FOUND} element={<PageNotFound />} />
      {user?.user.type === UserType.MASTER && (
        <>
          <Route path={AppRoutePaths.USERS_CREATE} element={<UserCreate />} />
          <Route
            path={AppRoutePaths.COMPANIES_CREATE}
            element={<CompanyCreate />}
          />
          <Route
            path={AppRoutePaths.COMPANIES_EDIT}
            element={<CompanyEdit />}
          />
          <Route path={AppRoutePaths.USERS_EDIT} element={<UserEdit />} />
          <Route path={AppRoutePaths.CASH_FLOW} element={<CashFlowList />} />
          <Route
            path={AppRoutePaths.WORK_SCALE_LIST}
            element={<WorkScaleList />}
          />
          <Route
            path={AppRoutePaths.WORK_SCALE_CREATE}
            element={<WorkScaleCreate />}
          />
          <Route
            path={AppRoutePaths.WORK_SCALE_EDIT}
            element={<WorkScaleEdit />}
          />
          <Route path={AppRoutePaths.WORK_ALERTS} element={<WorkAlerts />} />
          <Route
            path={AppRoutePaths.ACCESS_CREATE}
            element={<UserAccessCreate />}
          />
          <Route
            path={AppRoutePaths.ACCESS_EDIT}
            element={<UserAccessEdit />}
          />
          {genericManagerRoutes()}
        </>
      )}
      {user?.user.type === UserType.MANAGER && (
        <>
          {genericManagerRoutes()}
          {managerCreatorRoutes(user.user.role)}
        </>
      )}
      {user?.user.type === UserType.USER && (
        <>
          <Route
            path={AppRoutePaths.WORK_HISTORY_CREATE}
            element={<DailyWorkCreate />}
          />
        </>
      )}
    </Routes>
  );
}
