import { AxiosResponse } from 'axios';

import { User } from '../../models/user';
import { api } from '../../providers/base-api';
import {
  CreateUserAccessRequestData,
  RestoreUserRequestParams,
  UpdateUserAccessRequestData,
} from '../../utils/types/data/services/users';

export async function getUsersRequest(): Promise<AxiosResponse<User[]>> {
  const response = await api.get<User[]>('/users');

  return response;
}

export async function restoreUserRequest({
  id,
}: RestoreUserRequestParams): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`/users/restore/${id}`);

  return response;
}

export async function createUserAccessRequest(
  data: CreateUserAccessRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`/users/access`, data);

  return response;
}

export async function updateUserAccessRequest(
  id: number,
  data: UpdateUserAccessRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`/users/${id}/access`, data);

  return response;
}

export async function showUserRequest(
  id: number,
): Promise<AxiosResponse<User>> {
  const response = await api.get<User>(`/users/${id}`);

  return response;
}
